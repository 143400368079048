import React from 'react'
import Layout from '../components/LayoutView'
import { graphql, Link } from 'gatsby'

const Privacy = () => {
 
  return (
    <Layout title='PRIVACY POLICY'>
      
      <div>
        <div className='user-list-area pd-top-90 pd-bottom-70'>
          <div className='container'>
            <div className='section-title text-center'>
              <h2 className='title'>PRIVACY POLICY</h2>
            <p>We at Ownahome.ng are committed to protecting your privacy. This Privacy Policy explains how we collect, 
                use, and disclose information about you when you use our website and services. </p>
            
            </div>
            
            
<h3>INFORMATION WE COLLECT</h3>
<p>We collect information that you provide to us, such as your name, email address, phone number, and other contact information. 
We may also collect information about the properties you are interested in and any other preferences you may have.
We may also collect information automatically when you use our website, such as your IP address, browser type, and operating system. 
We may use cookies and other tracking technologies to collect this information.
</p>

<h3>HOW WE USE YOUR INFORMATION</h3>
<p>We use the information we collect to provide and improve our services, to communicate with you, and to personalize your experience on our website. 
    We may also use your information to send you promotional messages or marketing materials.
    We may share your information with third-party service providers who perform services on our behalf, such as website hosting, data analysis, and email delivery. We may also share your information with real estate agents and other third parties who can assist you in finding or selling a property.
</p>

<h3>YOUR CHOICES</h3>
<p>You may opt-out of receiving promotional messages or marketing materials from us by following the instructions in the message. 
    You may also disable cookies in your browser settings, although this may affect the functionality of our website.
</p>

<h3>SECURITY</h3>
<p>We take reasonable measures to protect your information from unauthorized access, use, or disclosure. 
    However, no system can be completely secure, and we cannot guarantee the security of your information.
</p>

<h3>CHILDREN'S PRIVACY</h3>
<p>Our website and services are not intended for children under the age of 13. 
    We do not knowingly collect or solicit personal information from children under the age of 13.
</p>

<h3>CHANGES TO THIS POLICY</h3>
<p> We may modify this Privacy Policy at any time. If we make material changes to this Policy, we will notify you by email or by posting a notice on our website. </p>

<h3>CONTACT US</h3>
<p> Our website and services are provided "as is" and without warranties of any kind, either express or implied.
    We do not guarantee that our website or services will be error-free, uninterrupted, or free from viruses or other harmful components.
    We do not endorse or make any representations about any third-party content or websites. </p>



          </div>
        </div>
      </div>
    </Layout>
  )
}


export default Privacy
